import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"
import * as Klaro from 'klaro/dist/klaro-no-css'
import ScrollTo from "stimulus-scroll-to"
import Carousel from "stimulus-carousel"
import AnimatedNumber from "stimulus-animated-number"
import { Dropdown } from 'bootstrap';
import { Modal } from "bootstrap";
import { Collapse } from 'bootstrap';
import AOS from 'aos';

let application = null

if (module.hot) {
  module.hot.accept() // tell hmr to accept updates

  if (module.hot.data) {
    application = module.hot.data.application // re-use old application if one was passed after update
  }
}

if (!application) {
  application = Application.start() // if no application so far, start it
}

if (module.hot) {
  module.hot.dispose(data => {
    data.application = application // on disposal of the old version (before reload), pass the old application to the new version
  })
}

const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
application.register("scroll-to", ScrollTo)
application.register("carousel", Carousel)
application.register("animated-number", AnimatedNumber)

AOS.init()

var klaroConfig = {
  storageMethod: 'cookie',
  cookieExpiresAfterDays: 365,
  privacyPolicy: {
    default: '/de-at/weitere-daten/datenschutz/',
    en: '/en/more-data/data-protection/',
    fr: '/fr/plus-dinformations/protection-des-donnees/'
  },
  hideDeclineAll: true,
  acceptAll: true,
  groupByPurpose: false,
  mustConsent: true,
  translations: {
    de: {
      ok: 'Allen zustimmen',
      acceptSelected: 'Auswahl speichern',
      consentModal: {
        title: 'Cookies, die wir speichern',
        description: 'Hier können Sie einsehen und anpassen, welche Informationen gespeichert werden.',
      },
      consentNotice: {
        description: 'Wir verwenden Cookies! Für einen optimalen Service auf unserer Website verwenden wir Cookies für folgende Zwecke: {purposes}. Sie können die Nutzung aller Cookies akzeptieren oder Ihre Auswahl individuell einstellen.',
        learnMore: 'Individuell einstellen'
      },
      gtm: {
        description: 'Sammeln von Besucherstatistiken und für Online-Marketing. Umfasst Google Tag Manager, Google Analytics und Facebook Pixel inklusive der Übermittlung der Daten in Drittländer außerhalb der EU.',
      },
      salesviewer: {
        description: 'Sammeln von Besucherstatistiken und für Online-Marketing.',
      },
      purposes: {
        analytics: 'Besucher-Statistiken und Online-Marketing',
      },
    },
    en: {
      consentModal: {
        description: 'Here you can see and customize the information that we collect about you.',
      },
      gtm: {
        description: 'Collecting of visitor statistics. Includes Google Tag Manager, Google Analytics and Facebook Pixel',
      },
      purposes: {
        analytics: 'Analytics',
      },
    },
    fr: {
      ok: 'Autoriser tous les cookies',
      acceptAll: "Accepter tout",
      acceptSelected: "Accepter sélectionné",
      consentNotice: {
        description: 'Bonjour ! Pourrions-nous activer des services supplémentaires pour nos {purposes} ?',
      },
      gtm: {
        description: 'Collecte de statistiques sur les visiteurs. Inclut Google Tag Manager, Google Analytics et Facebook Pixel',
      },
      purposes: {
        analytics: 'Statistiques',
      },
      salesviewer: {
        description: 'Collecte de statistiques sur les visiteurs.',
      },
    }
  },

  services: [
    {
      name: 'gtm',
      title: 'Google/Facebook',
      purposes: ['analytics'],
      cookies: [
        /^_gat_UA-.*$/,
        /^_gat_gtag_.*$/,
        /^_dc_gtm_.*$/,
        '_ga',
        '_gid',
        '_gat',
        '_fbp'
      ],
    },
    {
      name: 'youtube',
      purposes: ['content'],
    },
  ],
};

const countryCode = document.documentElement.getAttribute('data-country');

if (countryCode === 'de') {
  klaroConfig.privacyPolicy.default = '/de-de/weitere-daten/datenschutz/';
  klaroConfig.cookieName = 'klaro_de';

  klaroConfig.services.push({
    name: 'salesviewer',
    title: 'Salesviewer',
    purposes: ['analytics'],
    cookies: [],
  });

  klaroConfig.services.push({
    name: 'bwtjobs',
    title: 'BWT Jobs',
    purposes: ['content'],
    cookies: [],
  });
}

if (document.documentElement.lang === 'fr' && countryCode === 'ch') {
  klaroConfig.privacyPolicy.fr = '/fr-ch/plus-dinformations/protection-des-donnees/';
}

if (document.documentElement.lang === 'de' && countryCode === 'ch') {
  klaroConfig.privacyPolicy.default = '/de-ch/weitere-daten/datenschutz/';
}

if (countryCode === 'ch') {
  klaroConfig.cookieName = 'klaro_ch';

  klaroConfig.services.push({
    name: 'salesviewer',
    title: 'Salesviewer',
    purposes: ['analytics'],
    cookies: [],
  });

  klaroConfig.services.push({
    name: 'klimafoerderrechner',
    title: 'Klimaförderrechner',
    purposes: ['content'],
    cookies: [],
  });

  klaroConfig.services.push({
    name: 'bwtjobs',
    title: 'BWT Jobs',
    purposes: ['content'],
    cookies: [],
  });
}

if (!document.body.classList.contains('disable-klaro')) {
  window.klaro = Klaro;
  window.klaroConfig = klaroConfig;
  Klaro.setup(klaroConfig);
}

